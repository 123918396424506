<template>
  <div
    class="bg-[#ffffff1a] backdrop-filter backdrop-blur-20px p-20px rounded-24px"
  >
    <div class="flex justify-between py-5px">
      <div>{{ data.name0 }} - {{ data.name1 }}</div>
      <div>{{ data.weight * 100 }}%</div>
    </div>
    <div class="flex justify-between py-5px">
      <div>{{ $t('head.aaa5') }} {{data.name0}}</div>
      <div>{{ price1 }} </div>
    </div>
    <div class="flex justify-between py-5px">
      <div>{{ $t('head.aaa5') }} {{data.name1}}</div>
      <div>{{ price2  }} </div>
    </div>
    <!-- <div class="mt-9px flex">
      <div
        class="radio"
        @click="onChange(item.value)"
        :class="{ active: val == item.value }"
        v-for="item in list"
        :key="item.value"
      >
        {{ item.label }}
      </div>
    </div> -->
    <div class="mt-16px flex">
      <div
        @click="onApprove(data.token0,price1,data.decimal0)"
        class="h-34px flex items-center justify-center flex-1 rounded-12px approve1"
      >
        <img
          src="~@/assets/new/06.png"
          class="w-16px h-16px mr-6px"
          alt=""
          srcset=""
        />
        <span>{{ $t('head.aa7') }} {{data.name0}}</span>
      </div>
      <div
        @click="onApprove(data.token1,price2,data.decimal1)"
        class="h-34px flex items-center justify-center flex-1 rounded-12px approve1 ml-25px"
      >
        <img
          src="~@/assets/new/06.png"
          class="w-16px h-16px mr-6px"
          alt=""
          srcset=""
        />
        <span>{{ $t('head.aa7') }} {{data.name1}}</span>
      </div>
    </div>
    <div>
      <div
        @click="onUpgrade"
        class="h-34px flex items-center justify-center flex-1 rounded-12px mt-20px upgrade1"
      >
        <img
          src="~@/assets/new/05.png"
          class="w-16px h-16px mr-6px"
          alt=""
          srcset=""
        />
        <span>{{ $t('head.aa8') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    node: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Array,
      default: () => [
        {
          label: '50%',
          value: 0,
        },
        {
          label: '60%',
          value: 1,
        },
        {
          label: '70%',
          value: 2,
        },
      ],
    },
  },
  computed: {
    val: {
      get() {
        return this.value
      },
      set(e) {
        this.$emit('input', e)
      },
    },
    price1(){
      return (1 / this.data.price1 * this.node.amount * this.data.weight).toFixed(2)
    },
    price2(){
      return (this.data.price2 * this.node.amount * (1-this.data.weight)).toFixed(2)
    }
  },
  created() {},
  methods: {
    onChange(e) {
      if (e == this.val) return
      this.val = e
    },
    onApprove(e,b,d) {
      this.$emit('approve', e,parseFloat(b),this.data,d)
    },
    onUpgrade() {
      this.$emit('upgrade', this.data)
    },
  },
}
</script>
<style lang="scss" scoped>
.radio {
  @apply text-13px text-center w-53px h-26px flex items-center justify-center border border-[#ffffff] border-opacity-20 border-solid rounded-8px mr-8px;
  &.active {
    @apply bg-[#FFFFFF] bg-opacity-20;
  }
}

.approve1 {
  @apply bg-gradient-to-l from-[#2586de] from-opacity-30 to-[#e33bd8] to-opacity-30 border border-[#e33bd8];
}
.upgrade1 {
  @apply bg-gradient-to-l from-[#2586de] to-[#e33bd8];
}
</style>
