<template>
  <div class="relative page-bg-gradient min-h-full">
    <Headers :title="$t('node')"></Headers>
    <div
      class="absolute top-31px right-0 w-138px h-207px bg-[#7fd5b299] !filter !blur-100px z-99"
    ></div>
    <div class="absolute top-53px w-full left-0 bottom-0">
      <div
        class="bg-img w-329px h-329px mx-auto flex items-center justify-center"
      > 
        <div class="w-175px h-175px !bg-c rounded-full text-center">
          <div class="flex text-15px py-6px justify-center text-18px">
            <div class="text-[#ffffffb3]">{{ $t('head.overout') }}：</div>
            <div>{{ overamount }} USDT</div>
          </div>
          <div class="mt-10px text-25px font-bold font-Gilroy leading-30px">
            {{ balance }}
          </div>
          <div class="text-15px text-[#ffffffb3] uppercase mt-3px leading-21px">
            {{ $t('head.IPHBalance') }}
          </div>
          <div class="mt-16px">
            <van-popover
              v-model="showPopover"
              theme="dark"
              trigger="click"
              :actions="nodeList"
              @select="onSelect"
            >
              <template #reference>
                <div
                  class="w-106px h-34px rounded-full border border-[#ffffff33] flex items-center justify-center px-6px"
                >
                  <span class="truncate text-13px">{{ currentObj.text }}</span>
                  <img
                    class="w-16px h-16px ml-4px"
                    src="~@/assets/new/down-icon.png"
                    alt=""
                    srcset=""
                  />
                </div>
              </template>
            </van-popover>
          </div>
        </div>
      </div>
    </div>

    <div class="px-24px pt-302px pb-20px">
      <div
        class="bg-[#ffffff1a] backdrop-filter backdrop-blur-20px p-20px rounded-24px"
      >
        <div class="flex justify-between text-15px py-6px mt-10px">
          <div class="text-[#ffffffb3]">{{ $t('head.amounts') }}</div>
          <div>{{ currentObj.amount }}</div>
        </div>
        <div class="flex justify-between text-15px py-6px">
          <div class="text-[#ffffffb3]">{{ $t('head.hash') }}</div>
          <div>{{ currentObj.hashpower }}</div>
        </div>
        <div class="flex justify-between text-15px py-6px">
          <div class="text-[#ffffffb3]">{{ $t('head.overtime') }}</div>
          <div>{{ currentObj.out }}</div>
        </div>
        
      </div>
      <div class="mt-20px">
        <ItemComp
          v-for="(item, index) in vippools"
          :key="index"
          v-model="item.rid"
          :data="item"
          class="mb-16px"
          @approve="onApprove"
          @upgrade="onSubmit"
          :node="currentObj"
        ></ItemComp>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from '@/mixins/index'
import Headers from '@/components/headers'
import ItemComp from './components/itemComp'
import { BigNumber } from 'bignumber.js'
export default {
  components: {
    Headers,
    ItemComp,
  },
  data() {
    return {
      overamount:'0',
      showPopover: false,
      value: 0,
      vippools: [],
      balance: '0',
      jingdu: 1,
      nodeList: [
        {
          value: 0,
          text: this.$t('head.vip1'),
          amount: 100,
          hashpower: '500%',
          out: '300%',
          className: 'w-node',
        },
        {
          value: 1,
          text: this.$t('head.vip2'),
          amount: 500,
          hashpower: '600%',
          out: '300%',
          className: 'w-node',
        },
        {
          value: 2,
          text: this.$t('head.vip3'),
          amount: 1000,
          hashpower: '700%',
          out: '400%',
          className: 'w-node',
        },
        {
          value: 3,
          text: this.$t('head.vip4'),
          amount: 3000,
          hashpower: '1000%',
          out: '400%',
          className: 'w-node',
        },
        {
          value: 4,
          text: this.$t('head.djs'),
          amount: 10000,
          hashpower: '1000%',
          out: '400%',
          className: 'w-node',
        },
      ],
    }
  },
  mixins: [mixin],
  watch: {
    async 'global.account'() {
      await this.getPoolList()
      this.IPHpool2_contract = new this.global.web3.eth.Contract(
        this.poolabi.iphpool_abi2,
        this.pool.iphpool2
      )

      const tokenPrice = await this.IPHpool2_contract.methods
        .getPrice(this.pool.USDT, this.pool.iph)
        .call()

      this.vippools.map((e) => {
        e.node = this.pool[`${e.name0}${e.name1}_Node`]
        e.price2 = (tokenPrice[1] / 1e18).toFixed(4)
        
        if (e.node) {
          e.node_contract = new this.global.web3.eth.Contract(
            this.poolabi.NodeBy_abi,
            e.node
          )
          e.node_contract.methods
            .getPrice(e.token0)
            .call()
            .then((resData) => {
              if(e.name0=='MTC'){
                resData[0] = '1000000000000000000'
              }
              const jd = resData[1] / resData[0]
              e.price1 = jd.toFixed(3)
              console.log(resData);
            })
        }
      })

      this.IPH_contract = new this.global.web3.eth.Contract(
        this.poolabi.iph_abi,
        this.pool.iph
      )

      this.IPHpool1_contract = new this.global.web3.eth.Contract(
        this.poolabi.iphpool_abi1,
        this.pool.iphpool1
      )

      this.iphInvitation_abi = new this.global.web3.eth.Contract(
        this.poolabi.iphInvitation_abi,
        this.pool.iphInvitation
      )

      this.getbalance()
      this.getOverRewards()
    },
  },

  created() {},

  computed: {
    currentObj() {
      const obj = this.nodeList.find((e) => e.value === this.value)
      return obj ?? {}
    },
  },

  methods: {
    getOverRewards() {
      this.iphInvitation_abi.methods
        .getOverRewards(this.global.account)
        .call()
        .then((res) => {
          this.overamount = res 
        })
    },
    async onApprove(token, price, e,decimal) {
      console.log(decimal);
      if (!e.node) return this.$toast(this.$t('head.dev'))

      const contract = new this.global.web3.eth.Contract(
        this.poolabi.erc20abi,
        token
      )

      const amount = (price * 10 ** decimal * 100).toFixed(0)

      contract.methods
        .approve(e.node, this.global.web3.utils.numberToHex(BigNumber(amount)))
        .send({
          from: this.global.account,
        })
        .then(() => {
          this.$toast('APPROVE SUCCESS')
        })
        .catch((err) => {
          this.$toast(err)
        })
    },
    //升级
    onSubmit(e) {
      if (!e.node) return this.$toast(this.$t('head.dev'))
      const node_contract = new this.global.web3.eth.Contract(
        this.poolabi.NodeBy_abi,
        e.node
      )
      node_contract.methods
        .vipNode(this.currentObj.value)
        .send({
          from: this.global.account,
        })
        .then(() => {
          this.$toast('SUCCESS')
        })
        .catch((err) => {
          this.$toast(err)
        })
    },
    onSelect(e) {
      this.value = e.value
    },
    async getPoolList() {
      const { data } = await this.$axios.get(this.listdata + '/vippool/list')
      this.vippools = data.data.map((e) =>
        Object.assign({
          ...e,
          rid: 0,
          price1: 0,
          price2: 0,
          node: this.pool[`${e.name0}${e.name1}_Node`],
          weight: e.name0 == 'NOVA' ? 0.6 : 0.5,
        })
      )
    },

    getbalance() {
      this.IPH_contract.methods
        .balanceOf(this.global.account)
        .call()
        .then((res) => {
          this.balance = (res / 1e18).toFixed(3)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.bg-img {
  background: url('~@/assets/new/04.png') no-repeat;
  background-size: contain;
}
.bg-c {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #40f0b100 41.15%,
    #aaccff33 100%
  );
}
</style>
